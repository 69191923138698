import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue from "vue";
import "./css/main.css";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Notifications from "@kyvg/vue3-notification";

const options = {
  color: "#bffaf3",
  failedColor: "#874b4b",
  thickness: "5px",
  transition: {
    speed: "0.2s",
    opacity: "0.6s",
    termination: 300
  },
  autoRevert: true,
  location: "left",
  inverse: false
};

/* Default title tag */
const defaultDocumentTitle = "FINT Power Partners";

/* Collapse mobile aside menu on route change & set document title from route meta */
router.beforeEach(to => {
  store.dispatch("asideMobileToggle", false);
  store.dispatch("asideLgToggle", false);

  store.dispatch("formScreenToggle", !!to.meta.formScreen);
});

router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} | ${defaultDocumentTitle}`;
  } else {
    document.title = defaultDocumentTitle;
  }
});

createApp(App)
  .use(store)
  .use(Notifications)
  .use(VueProgressBar)
  .use(router)
  .mount("#app");
