import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home";
import axios from "axios";

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard"
    },
    path: "/",
    name: "dashboard",
    component: Home,
    beforeEnter(to, from, next) {
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const access = userObject.access_level;

        if (access === "user") {
          next("/loans");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    }
  },
  {
    meta: {
      title: "Dashboard",
      formScreen: true
    },
    path: "/",
    name: "home",
    component: Home,
    beforeEnter(to, from, next) {
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const access = userObject.access_level;

        if (access === "user") {
          next("/loans");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    }
  },
  {
    meta: {
      title: "Login",
      formScreen: true
    },
    path: "/login",
    name: "login",
    component: () => import(/* webpackChunkName: "login" */ "../views/Login")
  },
  {
    meta: {
      title: "Loans"
    },
    path: "/loans",
    name: "loans",
    component: () => import(/* webpackChunkName: "login" */ "../views/Loans")
  },
  {
    meta: {
      title: "Profile"
    },
    path: "/profile",
    name: "profile",
    component: () => import(/* webpackChunkName: "login" */ "../views/Profile"),
    beforeEnter(to, from, next) {
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const access = userObject.access_level;

        if (access === "user") {
          next("/loans");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    }
  },
  {
    meta: {
      title: "Page Not Found",
      formScreen: true
    },
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "login" */ "../views/NotFound")
  },
  {
    meta: {
      title: "Borrowers"
    },
    path: "/borrowers",
    name: "borrowers",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Borrowers")
  },
  {
    meta: {
      title: "Performance"
    },
    path: "/performance",
    name: "performance",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Performance"),
    beforeEnter(to, from, next) {
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const access = userObject.access_level;

        if (access === "user") {
          next("/loans");
        } else {
          next();
        }
      } catch (error) {
        next();
      }
    }
  },
  {
    meta: {
      title: "Login",
      formScreen: true
    },
    path: "/discos/login",
    name: "disco_login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Discos/Login")
  },
  {
    path: "/discos",
    name: "discos",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Discos/Home")
  },
  {
    path: "/discos/loans",
    name: "discos_loans",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Discos/Loans")
  },
  {
    path: "/discos/performance",
    name: "discos_performance",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Discos/Performance")
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  }
});

export default router;
