<template>
  <title-bar :title-stack="titleStack" />
  <main-section>
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 mb-6">
      <card-widget
        class="tile"
        color="text-blue-500"
        :icon="mdiCash100"
        :number="parseFloat(wallet_balance)"
        prefix="₦"
        secondaryLabel="Liquidate"
        @click="toggleModal"
        label="Earnings/Income"
      />
      <card-widget
        class="tile"
        color="text-green-500"
        :icon="mdiAccountMultiple"
        :number="insights.registered_borrowers_count"
        label="Borrowers"
      />
      <card-widget
        class="tile"
        color="text-red-500"
        :icon="mdiFinance"
        :number="insights.total_loan_disbursed"
        label="Total Loan Disbursed"
      />

      <card-widget
        class="tile"
        prefix="₦"
        color="text-red-500"
        :icon="mdiRocketLaunch"
        :number="insights.total_loan_amount"
        label="Total Loan Amount"
      />
    </div>

    <card-component
      title="Performance"
      :icon="mdiFinance"
      :header-icon="mdiReload"
      class="mb-6"
      @header-icon-click="fillChartData"
    >
      <div v-if="chartData">
        <line-chart :data="chartDataPrimary" class="h-64" />
      </div>
    </card-component>

    <card-component
      :icon="mdiClockTimeSeven"
      title="Recent Loans"
      has-table
      :exportable="true"
      :csvFields="csvLabels"
      :csvName="'BMP Recent Loans.csv'"
      :csvArray="insights.loans"
    >
      <loan-table :loans="insights.loans" />
      <div v-if="loans.length < 1" class="text-center">
        <card-component empty />
      </div>
    </card-component>
  </main-section>

  <div
    v-if="showModal"
    class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
  >
    <div class="relative w-auto my-6 mx-auto max-w-xl">
      <!--content-->
      <div
        class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
      >
        <!--header-->
        <div
          class="flex items-start justify-center p-4 border-b border-solid border-blueGray-200 rounded-t"
        >
          <h3 class="text-lg text-center font-semibold">
            Withdrawal to Bank Account
          </h3>
        </div>
        <!--body-->
        <div class="relative p-6 flex-auto">
          <div v-if="has_bank_info">
            <field label="Enter Amount" help="Required. Amount to Withdraw">
              <control type="text" required v-model="withdrawal_amount" />
            </field>

            <field
              label="Confirm Password"
              help="Required. Enter your Password to Authorize transaction"
            >
              <control type="password" required v-model="password" />
            </field>
          </div>

          <p class="my-4 text-blueGray-500 text-md leading-relaxed" v-else>
            Settlement Account not Provided, Kindly proceed to your profile to
            the complete setup
          </p>

          <p class="text-white text-md">
            Settlement Account not Provided, Kindly proceed to your profile to
          </p>
        </div>

        <!--footer-->
        <div
          class="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b"
        >
          <button
            class="text-red-500 bg-transparent border border-none border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            v-on:click="toggleModal()"
          >
            Close
          </button>

          <button
            class="text-white bg-blue-400 border border-none border-red-500 hover:bg-blue-200 hover:text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            v-if="has_bank_info"
            v-on:click="doWithraw()"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
</template>

<script>
// @ is an alias to /src
import { ref, onMounted } from "vue";
import {
  mdiAccountMultiple,
  mdiCash100,
  mdiChartTimelineVariant,
  mdiClockTimeSeven,
  mdiFinance,
  mdiRocketLaunch,
  mdiMonitorCellphone
} from "@mdi/js";
import * as chartConfig from "@/components/Charts/chart.config";
import LineChart from "@/components/Charts/LineChart";
import MainSection from "@/components/MainSection";
import CardWidget from "@/components/CardWidget";
import CardComponent from "@/components/CardComponent";
import LoanTable from "@/components/LoanTable";
import menu from "@/menu.js";
import { notify } from "@kyvg/vue3-notification";

import Divider from "@/components/Divider";
import Field from "@/components/Field";
import Control from "@/components/Control";

import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import axios from "axios";

export default {
  name: "Home",
  components: {
    MainSection,
    LoanTable,
    Divider,
    Field,
    Control,
    LineChart,
    CardComponent,
    CardWidget
  },
  data: function() {
    return {
      insights: {},
      loans: [],
      showModal: false,
      has_bank_info: false,
      password: "",
      withdrawal_amount: 0,
      wallet_balance: 0.0,
      csvLabels: {
        loan_id: "Loan ID",
        amount: "Amount",
        reference: "Reference",
        created_at: "Date Created",
        customer_phone: "Borrower Phone"
      },
      chartDataPrimary: {
        labels: [],
        datasets: []
      }
    };
  },
  computed: {},
  async created() {
    this.$Progress.start();

    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
    let user = localStorage.getItem("user");
    if (user === null) {
      window.location.replace("/login");
    } else {
      let userObject = JSON.parse(user);
      let accessToken = userObject.access_token;

      this.has_bank_info = userObject.has_bank_info;
      let partnerMeta = userObject.partner_meta;
      let email = userObject.email;

      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

      let body = {
        partner_meta: partnerMeta,
        admin_email: email
      };

      await this.$store.dispatch("currentUser/getInsight", body);
      this.insights = this.$store.state.currentUser.user;
      this.loans = this.$store.state.currentUser.user.loans;
      this.wallet_balance = parseFloat(this.insights.wallet_balance);
    }
    this.$Progress.finish();

    this.fetchPerformanceData();
  },
  methods: {
    doWithraw() {
      this.$Progress.start();

      let user = localStorage.getItem("user");

      axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      let userObject = JSON.parse(user);
      let accessToken = userObject.access_token;
      let partnerMeta = userObject.partner_meta;
      let email = userObject.email;

      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;

      axios
        .post(process.env.VUE_APP_API_URL + "/api/wallet/withdraw", {
          partner_meta: partnerMeta,
          transaction_pin: this.password,
          email: email,
          amount: parseInt(this.withdrawal_amount)
        })
        .then(data => {
          this.$Progress.finish();
          this.withdrawal_amount = 0;
          this.password = "";
          if (data.data.status) {
            this.showModal = !this.showModal;
            this.wallet_balance = parseFloat(data.data.data.balance);
            notify({
              type: "success",
              title: "Withdrawal Successful"
            });
          } else {
            let a = 0;
            for (a = 0; a < data.data.errors.length; a++) {
              notify({
                type: "error",
                title: data.data.errors[a]
              });
            }
          }
        })
        .catch(data => {
          this.$Progress.finish();
          notify({
            type: "error",
            title: "Something went wrong, Please try again"
          });
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    fetchPerformanceData() {
      let user = localStorage.getItem("user");

      axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      let userObject = JSON.parse(user);
      let accessToken = userObject.access_token;
      let partnerMeta = userObject.partner_meta;
      let email = userObject.email;

      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      const chartColors = {
        default: {
          lineColor: "#000",
          primary: "#00FF00"
        }
      };

      const labels = [];

      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ];

      for (let i = 1; i <= 13; i++) {
        labels.push(months[i - 1]);
      }
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/dashboard/fetchPartnerInsight",
          {
            partner_meta: partnerMeta,
            admin_email: email
          }
        )
        .then(res => {
          const performanceChartAmount = n => {
            return res.data.data.performanceAmount;
          };

          const performanceChartData = n => {
            return res.data.data.performanceData;
          };

          const performanceChartAmountObject = (color, points) => {
            return {
              fill: false,
              borderColor: "#41A0D8",
              borderWidth: 2,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#41A0D8",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#41A0D8",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 4,
              label: "Total Amount(₦)",
              data: performanceChartAmount(points),
              tension: 0.5,
              cubicInterpolationMode: "default"
            };
          };

          const performanceChartDataObject = (color, points) => {
            return {
              fill: false,
              borderColor: "#000",
              borderWidth: 0,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#000",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#41A0D8",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 0,
              label: "Loan Disbursed",
              data: performanceChartData(points),
              tension: 0.5,
              cubicInterpolationMode: "default"
            };
          };

          this.chartDataPrimary = {
            labels: labels,
            datasets: [
              performanceChartDataObject("lineColor", 13),
              performanceChartAmountObject("primary", 13)
            ]
          };
        })
        .catch(error => {
          window.location.replace("/login");
        });
    }
  },
  setup() {
    const titleStack = ref(["Admin", "Dashboard"]);

    const chartData = ref(null);

    const fillChartData = () => {
      chartData.value = [];
    };

    onMounted(() => {
      fillChartData();
    });

    return {
      titleStack,
      chartData,
      fillChartData,
      mdiAccountMultiple,
      mdiCash100,
      mdiChartTimelineVariant,
      mdiFinance,
      mdiRocketLaunch,
      mdiClockTimeSeven,
      mdiMonitorCellphone
    };
  }
};
</script>
