import axios from "axios";
import { notify } from "@kyvg/vue3-notification";

const state = {
  user: {},
  loans: [],
  borrowers: [],
  profile: {},
  analytics: []
};
const getters = {
  getUser(state) {
    return state.user;
  },
  getAnalytics(state) {
    return state.analytics;
  }
};
const actions = {
  async loginUser({ commit }, form) {
    await axios
      .post(process.env.VUE_APP_API_URL + "/api/login", {
        email: form.email,
        password: form.password
      })
      .then(res => {
        if (res.data.status) {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          localStorage.setItem("profile", JSON.stringify(res.data.data));
          window.location.replace("/");
        } else {
        }
      })
      .catch(error => {
        notify({
          type: "error",
          title: "Invalid email/password entered"
        });
      });
  },
  async loginDisco({ commit }, form) {
    await axios
      .post(process.env.VUE_APP_API_URL + "/api/disco/login", {
        email: form.email,
        password: form.password
      })
      .then(res => {
        if (res.data.status) {
          localStorage.setItem("disco", JSON.stringify(res.data.data));
          localStorage.setItem("disco_profile", JSON.stringify(res.data.data));
          window.location.replace("/discos");
        } else {
        }
      })
      .catch(error => {
        notify({
          type: "error",
          title: "Invalid email/password entered"
        });
      });
  },
  async getInsight({ commit }, key) {
    await axios
      .post(
        process.env.VUE_APP_API_URL + "/api/dashboard/fetchPartnerInsight",
        key
      )
      .then(data => {
        if (data.data.status) {
          commit("setInsight", data.data.data);
          commit("setLoans", data.data.data.loans);
          commit("setBorrowers", data.data.data.borrowers);
          localStorage.setItem(
            "performanceData",
            JSON.stringify(data.data.data)
          );
        } else {
          window.location.replace("/login");
        }
      })
      .catch(error => {
        window.location.replace("/login");
      });
  },
  async getDiscoInsight({ commit }, key) {
    await axios
      .post(
        process.env.VUE_APP_API_URL + "/api/dashboard/fetchDiscoInsight",
        key
      )
      .then(data => {
        if (data.data.status) {
          commit("setInsight", data.data.data);
          commit("setLoans", data.data.data.loans);
          commit("setBorrowers", data.data.data.borrowers);
          localStorage.setItem(
            "performanceData",
            JSON.stringify(data.data.data)
          );
        } else {
          window.location.replace("/discos/login");
        }
      })
      .catch(error => {
        window.location.replace("/discos/login");
      });
  }
};
const mutations = {
  setInsight(state, data) {
    state.user = data;
  },
  setLoans(state, data) {
    state.loans = data;
  },
  setBorrowers(state, data) {
    state.borrowers = data;
  },
  setAnalytics(state, data) {
    state.analytics = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
