import { mdiAccountMultiple, mdiViewDashboard, mdiFinance } from "@mdi/js";

export default [
  [
    {
      to: "/",
      icon: mdiViewDashboard,
      label: "Dashboard"
    }
  ],
  [
    {
      to: "/loans",
      icon: mdiFinance,
      label: "Loans"
    }
  ],
  [
    {
      to: "/borrowers",
      icon: mdiAccountMultiple,
      label: "Borrowers"
    }
  ],
  [
    {
      to: "/performance",
      icon: mdiFinance,
      label: "Performance"
    }
  ],
  [
    {
      to: "/profile",
      icon: mdiAccountMultiple,
      label: "Profile"
    }
  ]
];
