<template>
  <nav-bar />
  <router-view />
  <aside-menu :menu="menu" />
  <footer-bar />
  <notifications class="mt-4 mr-4" position="top right" />
  <vue-progress-bar></vue-progress-bar>
</template>

<script>
// @ is an alias to /src
import { useStore } from "vuex";
import menu from "@/menu.js";
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";

import axios from "axios";

export default {
  name: "Home",
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  created() {},

  methods: {},
  setup() {
    const store = useStore();

    return {
      menu
    };
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap");

#app {
  font-family: "Montserrat", sans-serif;
}
</style>
