<template>
  <aside
    v-show="!isFormScreen"
    class="w-60 fixed top-0 z-40 h-screen  transition-position lg:left-0 overflow-y-scroll
    scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-900 hover:scrollbar-thumb-gray-900"
    :class="[
      isAsideMobileExpanded ? 'left-0' : '-left-60',
      isAsideLgActive ? 'block' : 'lg:hidden xl:block'
    ]"
  >
    <div
      class="flex flex-col h-screen w-full from-gray-900 bg-gradient-to-b to-gray-700  text-white"
    >
      <div class="flex flex-col  w-full items-center gap-2 text-white pt-12">
        <img
          src="https://www.client.fint.ng/assets/img/fint_logo_64.png"
          class="pt-4 w-auto"
        />

        <h4>FINT Power Partners</h4>
        <p v-if="!isDisco" class="text-sm text-gray-300">@{{ partner_meta }}</p>
      </div>

      <div class="pt-6">
        <nav-bar-item
          type="hidden lg:flex xl:hidden"
          color=""
          class="pt-32"
          @click="asideLgClose"
        >
          <icon :path="mdiMenu" class="cursor-pointer" size="24" />
        </nav-bar-item>
        <div class="flex-1 px-3"></div>
      </div>
      <div>
        <template v-for="(menuGroup, index) in menu">
          <p
            v-if="typeof menuGroup === 'string'"
            :key="`a-${index}`"
            class="p-3 text-xs uppercase text-gray-400"
          >
            {{ menuGroup }}
          </p>
          <aside-menu-list
            v-else
            :key="`b-${index}`"
            :menu="menuGroup"
            @menu-click="menuClick"
          />
        </template>
      </div>
    </div>
  </aside>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import {
  mdiMenu,
  mdiAccountMultiple,
  mdiViewDashboard,
  mdiFinance
} from "@mdi/js";
import AsideMenuList from "@/components/AsideMenuList";
import NavBarItem from "@/components/NavBarItem";
import Icon from "@/components/Icon";

export default {
  name: "AsideMenu",
  components: { AsideMenuList, NavBarItem, Icon },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return { partner_meta: "", isDisco: false, disco_meta: "" };
  },
  created() {
    const currentUrl = window.location.href;
    if (currentUrl.includes("discos")) {
      // discos
      this.isDisco = true;
      const performance = {
        to: "/performance",
        icon: mdiFinance,
        label: "Performance"
      };
      try {
        for (var i = 0; i < this.menu.length; i++) {
          if (
            this.menu[i][0].label == "Profile" ||
            this.menu[i][0].label == "Borrowers"
          ) {
            this.menu.splice(i, 1);
          }
        }
        for (var i = 0; i < this.menu.length; i++) {
          this.menu[i][0].to = `/discos/${this.menu[i][0].to.replace("/", "")}`;
        }
        console.log(this.menu);
      } catch {}
    } else {
      this.getPartnerMeta();
      try {
        const user = localStorage.getItem("user");
        const userObject = JSON.parse(user);
        const access = userObject.access_level;
        if (access == !undefined || access == "user") {
          for (var i = 0; i < this.menu.length; i++) {
            if (
              this.menu[i][0].label == "Profile" ||
              this.menu[i][0].label == "Dashboard" ||
              this.menu[i][0].label == "Performance"
            ) {
              this.menu.splice(i, 1);
            }
          }

          this.menu.pop();
        }
      } catch {}
    }
  },
  methods: {
    getPartnerMeta() {
      try {
        const user = localStorage.getItem("user");

        const userObject = JSON.parse(user);
        const accessToken = userObject.access_token;
        const partnerMeta = userObject.partner_meta;
        this.partner_meta = this.formatPartnerMeta(partnerMeta);
      } catch (erorr) {}
    },
    formatPartnerMeta(meta) {
      if (meta.length > 10) {
        return meta.substring(0, 7) + "...";
      }
      return meta;
    }
  },
  setup() {
    const store = useStore();

    const isFormScreen = computed(() => store.state.isFormScreen);

    const isAsideMobileExpanded = computed(
      () => store.state.isAsideMobileExpanded
    );

    const isAsideLgActive = computed(() => store.state.isAsideLgActive);

    const asideLgClose = () => {
      store.dispatch("asideLgToggle", false);
    };

    const menuClick = (event, item) => {
      //
    };

    return {
      isFormScreen,
      isAsideMobileExpanded,
      isAsideLgActive,
      asideLgClose,
      menuClick,
      mdiMenu
    };
  }
};
</script>
