<template>
  <table>
    <thead>
      <tr>
        <th class="text-black text-extrabold">#ID</th>
        <th>Loan Amount</th>
        <th>Reference</th>
        <th>Customer</th>
        <th>Transaction</th>

        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="loan in itemsPaginated" :key="loan.loan_id">
        <td data-label="Id">{{ loan.loan_id }}</td>
        <td data-label="Amount">₦{{ loan.amount }}</td>

        <td data-label="Reference">{{ loan.reference }}</td>
        <td data-label="customer">{{ loan.customer_phone }}</td>
        <td data-label="customer">
          <Button
            class="text-green-500 text-sm text-center"
            @click="getTokenDetails(loan.reference)"
            >View Details</Button
          >
        </td>

        <td data-label="Created">
          <small class="text-gray-500" :title="loan.created_at.toString()">
            {{ formatDate(loan.created_at) }}
          </small>
        </td>
      </tr>
      <!-- <p v-if="loans.length < 1" class="text-center">No Data</p> -->
    </tbody>
  </table>
  <div class="table-pagination">
    <level>
      <jb-buttons>
        <jb-button
          v-for="page in pagesList"
          @click="currentPage = page"
          :active="page === currentPage"
          :label="page + 1"
          :key="page"
          small
        />
      </jb-buttons>
      <small>Page {{ currentPageHuman }} of {{ numPages }}</small>
    </level>

    <div
      v-if="showModal"
      class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
    >
      <div class="relative w-screen my-6 mx-auto max-w-xl">
        <div
          class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
        >
          <div
            class="flex items-start justify-center p-4 border-b border-solid border-blueGray-200 rounded-t"
          >
            <h3 class="text-lg text-center font-semibold">
              Recharge Token Details
            </h3>
          </div>
          <div class="relative p-6 flex-auto" ref="receipt" id="receipt">
            <div class="w-auto h-auto">
              <div class="flex justify-between  py-2 px-2">
                <p>Meter Number:</p>
                <p>{{ tokenData.meter_number }}</p>
              </div>
              <div class="flex justify-between  py-2 px-2">
                <div class=""><p>Recharge Token:</p></div>
                <div class="">
                  <p>{{ tokenData.stdToken }}</p>
                </div>
              </div>

              <div class="flex justify-between  py-2 px-2">
                <div class=""><p>Units:</p></div>
                <div class="">
                  <p>{{ tokenData.units }}</p>
                </div>
              </div>

              <div class="flex justify-between  py-2 px-2">
                <div class=""><p>Amount:</p></div>
                <div class="">
                  <p>₦{{ tokenData.amount }}</p>
                </div>
              </div>

              <div class="flex justify-between  py-2 px-2">
                <div class=""><p>Units Type</p></div>
                <div class=""><p>Kw/h</p></div>
              </div>
            </div>
          </div>

          <!--footer-->
          <div
            class="flex items-center justify-end p-3 border-t border-solid border-blueGray-200 rounded-b"
          >
            <button
              class="text-red-500 bg-transparent border border-none border-red-500 hover:bg-red-500 hover:text-white active:bg-red-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="toggleModal()"
            >
              Close
            </button>

            <button
              class="text-white bg-blue-400 border border-none border-red-500 hover:bg-blue-200 hover:text-white font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              type="button"
              v-on:click="doGeneratePdf(tokenData.stdToken)"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { mdiEye, mdiTrashCan } from "@mdi/js";
import ModalBox from "@/components/ModalBox";
import Level from "@/components/Level";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import JbButtons from "@/components/JbButtons";
import JbButton from "@/components/JbButton";
export default {
  name: "LoanTable",
  components: {
    Level,
    JbButtons,

    JbButton
  },

  props: {
    checkable: Boolean,
    loans: [],
    isDisco: Boolean
  },
  data: function() {
    return {
      showModal: false,
      tokenData: {
        stdToken: "",
        units: "",
        meter_number: "",
        amount: 1700,
        unitsType: "Kw/h"
      }
    };
  },
  methods: {
    formatDate(d) {
      var options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      };
      var date = new Date(d);

      return date.toLocaleDateString("en-US", options);
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    doGeneratePdf(ref) {
      var pdf = new jsPDF("l", "mm", [297, 210]);
      var element = document.getElementById("receipt");

      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();

      html2canvas(element).then(canvas => {
        var image = canvas.toDataURL("image/png");
        pdf.addImage(image, "JPEG", 0, 0, 0, 0);
        pdf.save(ref + ".pdf");
      });
    },
    getTokenDetails(ref) {
      let user = localStorage.getItem("user");
      this.$Progress.start();
      axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      let userObject = JSON.parse(user);
      let accessToken = userObject.access_token;
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
      axios
        .post(
          process.env.VUE_APP_API_URL + "/api/dashboard/loans/getRechargeToken",
          {
            provider: "EKEDP",
            paymentReference: ref
          }
        )
        .then(response => {
          this.$Progress.finish();

          if (response.data.status) {
            this.showModal = true;
            this.tokenData = response.data.data;
            this.tokenData.unitsType = "Kw/h";
          } else {
            notify({
              type: "error",
              title: "Failed to Get Token Details"
            });

            console.log(response.data.data);
          }
        })
        .catch(error => {
          this.$Progress.finish();
          window.replace("/login");
        });
    }
  },
  setup() {
    const store = useStore();

    const items = computed(() => store.state.currentUser.loans);

    const isModalActive = ref(false);

    const perPage = ref(10);

    const currentPage = ref(0);

    const checkedRows = ref([]);

    const itemsPaginated = computed(() =>
      items.value.slice(
        perPage.value * currentPage.value,
        perPage.value * (currentPage.value + 1)
      )
    );

    const numPages = computed(() =>
      Math.ceil(items.value.length / perPage.value)
    );

    const currentPageHuman = computed(() => currentPage.value + 1);

    const pagesList = computed(() => {
      const pagesList = [];

      for (let i = 0; i < numPages.value; i++) {
        pagesList.push(i);
      }

      return pagesList;
    });

    const remove = (arr, cb) => {
      const newArr = [];

      arr.forEach(item => {
        if (!cb(item)) {
          newArr.push(item);
        }
      });

      return newArr;
    };

    return {
      isModalActive,
      currentPage,
      currentPageHuman,
      numPages,
      checkedRows,
      itemsPaginated,
      pagesList,
      mdiEye,
      mdiTrashCan
    };
  }
};
</script>
